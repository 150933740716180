
import { Component, Vue } from 'vue-property-decorator'
import { apiFactoryDetail, apiFactoryEdit, apiFactoryAdd } from '@/api/shop'
import { PageMode } from '@/utils/type'
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    MaterialSelect
  }
})
export default class FactoryEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD; // 当前页面: add-添加角色 edit-编辑角色

    // 表
    lists: Array<object> = [];

    // 表单数据
    form: any = {
      name: '',
      address: '',
      status: 1,
      sort: '',
      super_admin: '',
      super_password: '',
      super_password_confirm: ''
    };

    // 表单校验
    rules: object = {
      super_admin: [
        {
          required: true,
          message: '请输入超级管理员账号',
          trigger: 'blur'
        }
      ],
      super_password: [
        {
          required: true,
          message: '请输入超级管理员密码',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 10,
          message: '密码由6到10位字符组成',
          trigger: 'blur'
        }
      ],
      super_password_confirm: [
        { required: true, message: '请输入确认密码', trigger: 'blur' },
        {
          min: 6,
          max: 10,
          message: '密码由6到10位字符组成',
          trigger: 'blur'
        }
      ],
      name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
    };

    factoryList: any[] = [];

    /** E Data **/

    /** S Methods **/
    // 提交表单
    onSubmit (formName: string) {
      if (this.form.super_password !== this.form.super_password_confirm) {
        this.$message({ type: 'error', message: '密码不一致' })
        return
      }
      const refs = this.$refs[formName] as HTMLFormElement
      refs.validate((valid: boolean): any => {
        if (!valid) return
        // 请求发送
        switch (this.mode) {
          case PageMode.ADD:
            return this.apiFactoryAdd()
          case PageMode.EDIT:
            return this.handleNoticeEdit()
        }
        this.handleNoticeEdit()
      })
    }

    // 添加
    async apiFactoryAdd (): Promise<void> {
      await apiFactoryAdd(this.form)
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 编辑
    async handleNoticeEdit (): Promise<void> {
      await apiFactoryEdit(this.form)
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 初始化表单数据
    async initFormDataForNoticeEdit (): Promise<void> {
      const res = await apiFactoryDetail({
        id: this.form.id
      })
      Object.keys(res).map((item) => {
        this.$set(this.form, item, res[item])
      })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created () {
      const query: any = this.$route.query
      if (query.mode) this.mode = query.mode

      if (this.mode === PageMode.EDIT) {
        this.form.id = query.id * 1
        this.initFormDataForNoticeEdit()
      }
    }

  /** E Life Cycle **/
}
